import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';

import { IDailyLimitField } from '@epi-models/containers/ManageBenefitGroups';

import { ManageBenefitLunchFormFields } from '../../helpers';

export function DailyLimitField({
  readOnly = false,
  options,
  label = '',
  helperText = ''
}: IDailyLimitField) {
  const name = ManageBenefitLunchFormFields.DailyLimit;
  const { control } = useFormContext();

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control
  });

  return (
    <TextField
      label={label}
      select
      value={value}
      readOnly={readOnly}
      id="daily-limit-select"
      error={!!error}
      helperText={error?.message ? error?.message : helperText}
      inputRef={ref}
      onChange={setValue}
      SelectProps={{
        MenuProps: {
          BackdropProps: {
            sx: {
              backgroundColor: 'transparent'
            }
          }
        }
      }}
    >
      {options
        .filter(option => option.upToDate)
        .map(option => (
          <TextField.Item key={option.id} value={option.id}>
            {option.display}
          </TextField.Item>
        ))}
    </TextField>
  );
}
