import React, { useEffect } from 'react';
import { Row, Collapse, Container } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { RadioGroup, FormHelperText, Box } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { FormFields } from '@epi-forms/helpers';
import { togglePrintInvoiceForPdf } from '@epi-actions/companyAccounts';

import { formatNumeric, formatCurrency } from '../../helpers/numeral';
import {
  PaymentMethods,
  getPaymentMinimalValue
} from '../../constants/paymentMethods';
import { TooltipInfo } from '../../components/TooltipInfo/TooltipInfo';
import storage from '../../helpers/StorageHelper';

import './PaymentMethod.scss';
import { ExternalPayment } from './ExternalPayment/ExternalPayment';
import { ForwardInvoice } from './ForwardInvoice/ForwardInvoice';
import { DownloadInvoice } from './DownloadInvoice/DownloadInvoice';
import { PaymentMethodField } from './PaymentMethodField/PaymentMethodField';

function PaymentMethod({
  i18n,
  disabled,
  creditCardFeePercent,
  onClickPaymentMethod,
  paymentMethods: { paymentMethods, isLoading, isError },
  isPaymentProcessing,
  disabledMethods,
  hiddenMethods,
  getPaymentMinValue,
  orderType,
  onPaymentMethodChanged,
  amountValue,
  isLoadCompanyAccountPage
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    watch
  } = useFormContext();

  const method = watch(FormFields.PaymentMethod);

  const creditCardMethods = paymentMethods.filter(
    t => t.type === PaymentMethods.CreditCard
  );

  const onlinePaymentMethods = paymentMethods.filter(
    t => t.type === PaymentMethods.Online
  );

  const isLessThanMinValue = paymentMethod => {
    return amountValue < getPaymentMinValue(paymentMethod);
  };

  const getErrorMessageKey = (paymentMethod, paymentMethodName) => {
    const minimalValue = getPaymentMinValue(paymentMethod);
    if (minimalValue > amountValue) {
      return {
        value: 'forms.payment_method.minimal_value_error',
        format: {
          minimalValue: formatCurrency(minimalValue),
          paymentMethod: i18n.t(paymentMethodName).toLowerCase()
        }
      };
    }

    return { value: 'forms.payment_method.maksuturva_error' };
  };

  useEffect(() => {
    storage.set('isPrintInvoicePdf', '');
    dispatch(togglePrintInvoiceForPdf(false));
  }, []);

  return (
    <div className="PaymentMethod pl-25 py-0">
      <Container fluid className="payment-method">
        <RadioGroup
          sx={
            errors.method && {
              '& .MuiFormControlLabel-label, span': {
                color: theme.warningColor
              }
            }
          }
        >
          {!hiddenMethods.includes(PaymentMethods.Online) && (
            <Row>
              <div data-test="online-payment-method-checkbox">
                <PaymentMethodField
                  id="paymentMethodOnlinePayment"
                  disabled={disabled}
                  label={i18n.t('forms.payment_method.online_payment')}
                  name={FormFields.PaymentMethod}
                  value={PaymentMethods.Online}
                  onChangeMethod={onPaymentMethodChanged}
                />
              </div>
            </Row>
          )}
          <Row>
            <Collapse
              unmountOnExit
              in={method === PaymentMethods.Online && !isLoadCompanyAccountPage}
            >
              <div className="online_payment">
                <ExternalPayment
                  isError={isError || isLessThanMinValue(PaymentMethods.Online)}
                  errorMessageKey={getErrorMessageKey(
                    PaymentMethods.Online,
                    'forms.payment_method.online_payment'
                  )}
                  isLoading={isLoading}
                  disabled={disabled}
                  methods={onlinePaymentMethods}
                  onClickPaymentMethod={onClickPaymentMethod}
                  isProcessing={isPaymentProcessing}
                />
              </div>
            </Collapse>
          </Row>
          {!hiddenMethods.includes(PaymentMethods.CreditCard) && (
            <Row>
              <div data-test="cc-payment-method-checkbox">
                <PaymentMethodField
                  id="paymentMethodCreditCardPayment"
                  disabled={disabled}
                  label={i18n.t('forms.payment_method.credit_card_payment')}
                  name={FormFields.PaymentMethod}
                  value={PaymentMethods.CreditCard}
                  onChangeMethod={onPaymentMethodChanged}
                />
              </div>
              {creditCardFeePercent > 0 && (
                <TooltipInfo
                  info={i18n.t(`forms.payment_method.credit_card_fee_info`, {
                    value: formatNumeric(creditCardFeePercent, '0.00')
                  })}
                  placement="right"
                />
              )}
            </Row>
          )}
          <Row>
            <Collapse unmountOnExit in={method === PaymentMethods.CreditCard}>
              <div className="credit_card">
                <ExternalPayment
                  isError={
                    isError || isLessThanMinValue(PaymentMethods.CreditCard)
                  }
                  errorMessageKey={getErrorMessageKey(
                    PaymentMethods.CreditCard,
                    'forms.payment_method.credit_card_payment'
                  )}
                  isLoading={isLoading}
                  disabled={disabled}
                  methods={creditCardMethods}
                  onClickPaymentMethod={onClickPaymentMethod}
                  isProcessing={isPaymentProcessing}
                />
              </div>
            </Collapse>
          </Row>
          {!hiddenMethods.includes(PaymentMethods.LinkToOtherPerson) && (
            <Row>
              <PaymentMethodField
                id="paymentMethodForwardInvoice"
                disabled={
                  disabled ||
                  disabledMethods.includes(PaymentMethods.LinkToOtherPerson)
                }
                label={i18n.t('forms.payment_method.forward_invoice_by_mail')}
                name={FormFields.PaymentMethod}
                value={PaymentMethods.LinkToOtherPerson}
                onChangeMethod={onPaymentMethodChanged}
              />
            </Row>
          )}
          <Row>
            <Collapse
              unmountOnExit
              in={method === PaymentMethods.LinkToOtherPerson}
            >
              <div>
                <ForwardInvoice disabled={disabled} orderType={orderType} />
              </div>
            </Collapse>
          </Row>
          {!hiddenMethods.includes(PaymentMethods.PrintInvoicePdf) && (
            <Row>
              <PaymentMethodField
                id="paymentMethodInvoice"
                disabled={
                  disabled ||
                  disabledMethods.includes(PaymentMethods.PrintInvoicePdf)
                }
                label={i18n.t('forms.payment_method.download_invoice_and_pay')}
                name={FormFields.PaymentMethod}
                value={PaymentMethods.PrintInvoicePdf}
                onChangeMethod={onPaymentMethodChanged}
              />
            </Row>
          )}
          <Row>
            <Collapse
              unmountOnExit
              in={method === PaymentMethods.PrintInvoicePdf}
            >
              <div>
                <DownloadInvoice disabled={disabled} orderType={orderType} />
              </div>
            </Collapse>
          </Row>
          {!hiddenMethods.includes(
            PaymentMethods.PrintInvoicePdfForTransferMoney
          ) && (
            <Row>
              <PaymentMethodField
                id="paymentMethodInvoiceForTransferMoney"
                disabled={
                  disabled ||
                  disabledMethods.includes(
                    PaymentMethods.PrintInvoicePdfForTransferMoney
                  )
                }
                label={i18n.t('forms.payment_method.download_invoice_and_pay')}
                name={FormFields.PaymentMethod}
                value={PaymentMethods.PrintInvoicePdfForTransferMoney}
                onChangeMethod={onPaymentMethodChanged}
              />
            </Row>
          )}
          <Row>
            <Collapse
              unmountOnExit
              in={method === PaymentMethods.PrintInvoicePdfForTransferMoney}
            >
              <Box fontSize={16} ml={3.5} color={theme.gray70}>
                <Trans
                  defaults={i18n.t(
                    'forms.payment_method.download_invoice_as_pdf_for_transfer_money'
                  )}
                  components={{ break: <br />, bold: <b /> }}
                />
              </Box>
            </Collapse>
          </Row>
          <FormHelperText
            sx={{ fontSize: theme.sizeExtraSmall }}
            error={!!errors.method}
          >
            {errors?.method?.message}
          </FormHelperText>
        </RadioGroup>
      </Container>
    </div>
  );
}

const paymentMethodsPropType = PropTypes.shape({
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  image_url: PropTypes.string.isRequired,
  image_width: PropTypes.number.isRequired,
  image_height: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PaymentMethods.CreditCard, PaymentMethods.Online])
    .isRequired
});

PaymentMethod.propTypes = {
  paymentMethods: PropTypes.shape({
    paymentMethods: PropTypes.arrayOf(paymentMethodsPropType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired
  }).isRequired,
  i18n: PropTypes.object.isRequired,
  onClickPaymentMethod: PropTypes.func.isRequired,
  onPaymentMethodChanged: PropTypes.func,
  disabled: PropTypes.bool,
  creditCardFeePercent: PropTypes.number,
  amountValue: PropTypes.number.isRequired,
  disabledMethods: PropTypes.arrayOf(PropTypes.string),
  hiddenMethods: PropTypes.arrayOf(PropTypes.string),
  isPaymentProcessing: PropTypes.bool,
  getPaymentMinValue: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  isLoadCompanyAccountPage: PropTypes.bool
};

PaymentMethod.defaultProps = {
  disabled: false,
  creditCardFeePercent: 0,
  disabledMethods: [],
  hiddenMethods: [],
  isPaymentProcessing: false,
  onPaymentMethodChanged: () => {},
  isLoadCompanyAccountPage: false
};

const mapStateToProps = state => ({
  getPaymentMinValue: type => getPaymentMinimalValue(type)(state)
});

const PaymentMethodWithI18n = withTranslation()(
  connect(mapStateToProps)(PaymentMethod)
);

export { PaymentMethodWithI18n as PaymentMethod };
