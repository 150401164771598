import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Link, Text } from 'edenred-ui';

import { IDetailDailyLimitStatement } from '@epi-models/containers/ManageBenefitGroups';

export const DetailDailyLimitStatement = ({
  benefitType,
  maxAmountPerPeriodDisplay,
  isVirikeBenefit
}: IDetailDailyLimitStatement) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        width={isVirikeBenefit ? 457 : 'inherit'}
        pb={isVirikeBenefit ? 2 : 'inherit'}
        id="benefit-group-details-daily-limit-statement"
      >
        <Text id="benefit-group-details-daily-limit-statement-text">
          <Trans
            defaults={t(
              `components.manage_benefit_${benefitType}.detail_daily_limit_statement`,
              {
                limit: maxAmountPerPeriodDisplay
              }
            )}
            components={{
              b: <b />,
              br: <br />
            }}
          />
        </Text>
      </Box>
      <br />
      <Text id="benefit-group-details-daily-limit-statement-link">
        <Link
          target="_blank"
          href={t(
            `components.manage_benefit_${benefitType}.detail_read_more_about_limits_link`
          )}
        >
          {t(
            `components.manage_benefit_${benefitType}.detail_read_more_about_limits_text`
          )}
        </Link>
      </Text>
    </>
  );
};
